.our-client {
   width: 100%;
   height: auto; /* Change height to auto for responsiveness */
   background-color: rgb(235, 233, 233);
   margin-top: 10px;
   text-align: center;
   padding: 20px; /* Add some padding */

 }

 
 .company {
   display: flex;
   flex-wrap: wrap; /* Add flex-wrap to allow items to wrap to the next line */
   justify-content: space-around;
   color: black;
 }
 
 .company-img {
  background-color: aliceblue;
  margin: 10px auto; /* Center items horizontally */
  border-radius: 5px;
  width: 200px; /* Fixed width for uniformity in carousel */
  max-width: 100%; /* Ensure responsiveness */
  flex-grow: 0; /* Prevent images from stretching unevenly */
  height: 200px; /* Fixed height for uniformity */
  background-size: cover; /* Adjust to cover the entire container */
  background-position: center;
  overflow: hidden; /* Prevent content overflow */
}

 
 @media screen and (max-width: 768px) {
   .company-img {
     width: calc(33.33% - 20px); /* Adjust width for smaller screens */
   }
 }
 
 @media screen and (max-width: 576px) {
   .company-img {
     width: calc(50% - 20px); /* Adjust width for even smaller screens */
   }
 }
 
 @media screen and (max-width: 400px) {
   .company-img {
     width: calc(100% - 20px); /* Adjust width for very small screens */
   }
 }
 