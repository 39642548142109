.LeaderTeam {
   width: 100%;
   padding: 20px;
   background-color: white;
   text-align: center;
   margin-top: 25px;
   /* Remove !important */
   opacity: 0;
   transform: translateY(20px);
   animation: fadeIn 0.8s ease-out forwards;
 }
 

@keyframes fadeIn {
   from {
      opacity: 0 !important;
      transform: translateY(20px) !important;
   }
   to {
      opacity: 1 !important;
      transform: translateY(0) !important;
   }
}

.LeaderTeam-Title {
   margin-bottom: 20px !important;
   font-size: 2em !important;
   color: #343a40 !important;
}

.cards-container {
   display: flex !important;
   flex-wrap: wrap !important;
   justify-content: center !important;
}

.card {
   flex: 1 1 calc(25% - 20px) !important; /* Take up 25% width with space for margin */
   box-sizing: border-box !important;
   margin: 10px !important;
   border: 1px solid #ddd !important;
   border-radius: 10px !important;
   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1) !important;
   overflow: hidden !important; /* Ensure content does not overflow */
   transition: box-shadow 0.3s ease-out !important;
}

.card:hover {
   box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5) !important;
}

.card-img-top {
   width: 100% !important;
   height: 200px !important; /* Set a fixed height */
   background-size: cover !important; /* Cover the entire area */
   background-position: center !important; /* Center the image */
   background-repeat: no-repeat !important; /* No repeat */
}

.card-body {
   padding: 15px !important;
}

.card-text {
   font-size: 1em !important;
   color: #6c757d !important;
}

@media (max-width: 768px) {
   .card {
      flex: 1 1 calc(50% - 20px) !important; /* Take up 50% width on medium screens */
   }
}

@media (max-width: 480px) {
   .card {
      flex: 1 1 calc(100% - 20px) !important; /* Take up 100% width on small screens */
   }
}
