.ServicePortfolio {
   display: flex;
   flex-wrap: wrap; /* Allow wrapping for smaller screens */
   padding: 20px;
   margin: 0 auto;
   max-width: 100%; /* Set a maximum width for larger screens */
   justify-content: space-around;
}
.ServicePortfolio-container h1{
   text-align: center;
}

.ServicePortfolio-items {
   flex: 1; /* Make items take equal width */
   margin: 10px; /* Add some margin between items */
   padding-left: 100px;
}

.ServicePortfolio h1 {
   text-align: center;
   margin-bottom: 20px;
}

.ServicePortfolio p {
   max-width: 800px;
   margin-bottom: 20px;
   line-height: 1.6;
   text-align: left; /* Ensure paragraph text is aligned to the left */
   margin-left: auto;
   margin-right: auto;
}

.ServicePortfolio ul {
   max-width: 800px; /* Match the width of the paragraph */
   text-align: left; /* Align the list items to the left */
   list-style-type: disc;
   padding-left: 20px; /* Add left padding for indentation */
   margin-left: auto;
   margin-right: auto;
}

.ServicePortfolio li {
   margin-bottom: 10px;
}

.ServicePortfolio img {
   max-width: 100%; /* Make sure the image is responsive */
   height: auto; /* Maintain aspect ratio */
   display: block; /* Remove any bottom space */
   margin-left: auto;
   margin-right: auto;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
   .ServicePortfolio {
      flex-direction: column; /* Stack items vertically on smaller screens */
      padding: 10px;
   }
   
   .ServicePortfolio p,
   .ServicePortfolio ul {
      max-width: 100%;
   }
   .ServicePortfolio-items{
      padding-left: 40px;
   }

}

@media screen and (max-width: 576px) {
   .ServicePortfolio h1 {
      font-size: 1.5rem;
   }

   .ServicePortfolio p,
   .ServicePortfolio ul {
      max-width: 100%;
      padding-left: 10px; /* Adjust left padding for smaller screens */
      padding-right: 10px; /* Adjust right padding for smaller screens */
   }
   .ServicePortfolio-items{
      padding-left: 20px;
   }
}
