/* CarouselComponent.css */
.custom-carousel {
  margin-top: 85px !important;
}

.carousel-item img {
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: contain; /* Ensures the image fits within the container without cropping */
  background-color: #f0f0f0; /* Optional: adds a background color for images with transparent areas */
}

@media (max-width: 768px) {
  .carousel-item img {
    max-height: 300px;
  }
}

@media (max-width: 576px) {
  .carousel-item img {
    max-height: 200px;
  }
}

.custom-carousel .carousel-control-prev-icon,
.custom-carousel .carousel-control-next-icon {
  background-color: #000000;
  border-radius: 50%;
}

.custom-carousel .carousel-indicators li {
  background-color: #ffcc00;
}

.custom-carousel .carousel-indicators .active {
  background-color: #ffffff;
}
