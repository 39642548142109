.aboutus {
   margin-top: 95px;
   width: 100%;
   height: 150px;
   display: flex; /* Use Flexbox */
   justify-content: center; /* Center horizontally */
   align-items: center; /* Center vertically */
   text-align: center; /* Center text horizontally */
   background-image: url("https://media.istockphoto.com/id/182061790/photo/white-wooden-board-background.jpg?s=1024x1024&w=is&k=20&c=Ecu_ZbSVP9AhRrJh5O8WW6n3UwbdVYrE4zshLIix-UU=");
}
