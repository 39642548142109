.OurClientele-header{
   width: 100%;
   height: 200px;
   margin-top: 95px;
   background-image: url("https://media.istockphoto.com/id/182061790/photo/white-wooden-board-background.jpg?s=1024x1024&w=is&k=20&c=Ecu_ZbSVP9AhRrJh5O8WW6n3UwbdVYrE4zshLIix-UU=");
   text-align: center;
   display: flex;
   justify-content: center; /* Center horizontally */
   align-items: center; /* Center vertically */
   margin-bottom: 20px;
}
.OurClientele-header h1{
   text-align: center;
}