.achievement {
  width: 100%;
  height: 700px;
  background-color: white;
  margin-top: 10px;
  text-align: center;
  background-image: url("https://images.unsplash.com/photo-1523287562758-66c7fc58967f?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-end;
}

.achievement-items {
  width: 40%;
  height: 100%;
  color: #FFFFFF;
  align-items: center;
  margin-right: 10px;
  background-color: rgba(0, 0, 0, 0.6); /* Added for better text readability */
  padding: 20px; /* Added padding for better spacing inside the div */
  box-sizing: border-box;
}


.achievement-items ul {
  list-style-type: disc; /* Use disc bullets */
  margin-top: 20px; /* Add some top margin for spacing */
  padding-left: 20px; /* Add left padding for indentation */
  text-align: left; /* Align the text to the left */
  color: #FFFFFF; /* Text color */
}

.achievement-items ul li {
  margin-bottom: 10px; /* Add bottom margin between list items */
}

/* Responsive Styles */
@media screen and (max-width: 1024px) {
  .achievement-items {
    width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .achievement {
    justify-content: center; /* Center the content on smaller screens */
    height: auto;
    padding: 20px;
  }

  .achievement-items {
    width: 80%;
    height: auto;
    margin: 20px 0;
  }
}

@media screen and (max-width: 576px) {
  .achievement-items {
    width: 100%;
    margin: 10px 0;
  }
}
