.ContactUs-items {
   padding: 20px;
   display: flex;
   flex-wrap: wrap;
   justify-content: space-around;

 }
 
 .Address {
   display: flex;
   flex-wrap: wrap;
   justify-content: space-between;
   width: 48%;
   border-radius: 10px;
   margin-bottom: 20px;
 }
 
 .Address-detail {
   flex: 1 1 45%;
   margin-bottom: 20px;
   padding: 10px;
   box-sizing: border-box;
 }
 
 .Address-detail h4 {
   display: flex;
   align-items: center;
   margin-bottom: 10px;
 }
 
 .Address-detail h4 .fa-icon {
   margin-right: 10px;
 }
 
 .Address-detail p {
   margin-left: 25px;
 }
 
 .ContactForm {
   flex: 1 1 45%;
   margin-bottom: 20px;
   padding: 10px;
   box-sizing: border-box;
 }
 
 .form-group {
   margin-bottom: 15px;
 }
 
 .form-group label {
   display: inline-block;
   margin-bottom: 5px;
 }
 
 .form-check {
   margin-bottom: 15px;
 }
 
 .btn-block {
   width: 100%;
   background-color: #0098DA;
   border: none;
   padding: 10px;
   color: white;
   cursor: pointer;
   text-align: center;
 }
 
 .location {
   width: 100%;
   height: 600px;
   border: 1px solid black;
   border-radius: 10px;
   background-image: url("https://images.unsplash.com/photo-1604357209793-fca5dca89f97?q=80&w=1528&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   margin-top: 20px;
 }
 
 /* Responsive adjustments */
 @media (max-width: 768px) {
   .Address, .ContactForm {
     width: 100%;
   }
 
   .Address-detail {
     flex: 1 1 100%;
   }
 }
 