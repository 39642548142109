.Not-Found {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 50vh;
   background-color: #f8f8f8;
   font-family: Arial, sans-serif;
   color: #333;
   text-align: center;
 }
 
 .Not-Found h1 {
   font-size: 3rem;
   font-weight: bold;
   color: #e74c3c;
   margin: 0;
 }
 
 .Not-Found::before {
   content: "404";
   font-size: 10rem;
   color: rgba(231, 76, 60, 0.1);
   position: absolute;
   font-weight: bold;
   z-index: -1;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
 }
 