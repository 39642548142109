.PolicyIntegration {
  display: flex;
  flex-wrap: wrap;
  background-image: url("https://images.unsplash.com/photo-1554227231-54aa5db01c51?q=80&w=1476&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  background-size: cover; /* Ensure the background image covers the whole container */
  background-position: center;
  
  /* Animation */
  opacity: 0;
  transform: translateY(20px);
  animation: fadeIn 0.8s ease-out forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.quality {
  color: #042d58;
}

.Policy {
  flex: 1 0 50%; /* Two columns on large screens */
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  height: auto; /* Make height auto to adjust content dynamically */
  border: 0.1px solid black;
  border-radius: 10px;
  margin-bottom: 20px;
  padding-left: 30px;
  padding-right: 20px;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent background for readability */
  /* Optional: Add box shadow for better visuals */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.Policy p {
  text-align: left;
}

.Policy ul {
  list-style-type: disc;
  margin-top: 20px;
  padding-left: 20px;
  text-align: left;
}

.Policy li {
  margin-bottom: 10px;
}

.Policy-img {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  /* Add padding for responsiveness */
  padding: 0;
}

.Policy-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center; /* Ensure the image stays centered */
}

/* Media query for responsiveness */
@media only screen and (max-width: 768px) {
  .Policy {
    flex: 1 0 100%; /* Full width on smaller screens */
  }

  .Policy-img {
    height: 250px; /* Adjust the image height for smaller screens */
  }

  .Policy-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media only screen and (max-width: 576px) {
  .Policy {
    flex: 1 0 100%;
    padding: 10px; /* Adjust padding for smaller screens */
  }

  .Policy-img {
    height: 200px; /* Adjust height for very small screens */
  }
}
