.MissionAccomplished {
   text-align: center;
   padding: 20px;
}

.MissionAccomplished-items {
   width: 100%;
   height: auto; /* Adjusted for responsiveness */
   border: 1px solid black;
   display: flex;
   justify-content: center;
   flex-wrap: wrap;
   padding-right: 40px;
   padding-left: 40px;
   box-sizing: border-box; /* Added box-sizing for better layout control */
}

.MissionAccomplished-pic {
   width: 20%;
   height: auto;
   margin: 10px; /* Space between images */
   display: flex;
   justify-content: center;
   align-items: center;
   overflow: hidden; /* Prevent text overflow */
   text-overflow: ellipsis; /* Adds '...' for overflow text */
   white-space: nowrap; /* Prevent text from wrapping */
}

.MissionAccomplished-pic img {
   width: 100%; /* Ensure images fill the container */
   height: auto;
   object-fit: contain; /* Keeps aspect ratio intact */
}

@media (max-width: 1200px) {
   .MissionAccomplished-pic {
      width: 25%; /* 4 items per row on large screens */
   }
}

@media (max-width: 992px) {
   .MissionAccomplished-pic {
      width: 33.33%; /* 3 items per row on medium screens */
   }
}

@media (max-width: 768px) {
   .MissionAccomplished-pic {
      width: 50%; /* 2 items per row on small screens */
   }
}

@media (max-width: 576px) {
   .MissionAccomplished-pic {
      width: 100%; /* 1 item per row on extra small screens */
   }
}
