.our-journey {
   width: 100%;
   padding: 20px;
   background-color: white;
   text-align: center;
   display: flex;
   flex-direction: column;
   align-items: center;
   margin-top: 30px;
   background-image: url("https://images.unsplash.com/photo-1548211749-2f184133850c?q=80&w=1628&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
 }
 
 .carousel-container {
   margin-top: 20px;
   width: 90%;
 }
 
 .card {
   width: 100%;
   border: none;
   border-radius: 10px;
   overflow: hidden;
 }
 
 .card-img-top {
   height: 300px;
   object-fit: contain;
   width: 100%;
 }
 
 .card-body {
   background-color: white;
   color: white;
   padding: 20px;
   text-align: center;
 }
 
 .card-body .card-title {
   color: rgb(61, 192, 239);
 }
 
 .card-body .card-text {
   color: #111111;
 }
 
 @media screen and (max-width: 768px) {
   .card-img-top {
     height: 200px;
   }
 
   .card-body {
     padding: 10px;
   }
 }
 
 @media screen and (max-width: 576px) {
   .card-body {
     font-size: 0.9rem;
   }
 }
 