.modal-overlay {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.8);
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 1000;
}

.modal-content {
   max-width: 90%;
   max-height: 90%;
}

.modal-content img {
   max-width: 100%;
   max-height: 100%;
   border: 5px solid white;
}
