/* General container styling */
.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
}

/* Individual item styling */
.container-items {
  flex: 1 1 45%; /* Flex basis for responsiveness */
  margin: 20px;
  text-align: center;
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.container-items:hover {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
  transform: scale(1.05); /* Scale up on hover */
}

/* Headings styling */
.container-items h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
  animation: fadeInDown 0.6s ease-in-out; /* Fade-in-down animation */
}

.quality {
  color: #0098DA;
}

/* Paragraph styling */
.container-items ul {
  text-align: left;
  color: #555;
  animation: fadeIn 0.8s ease-in-out; /* Fade-in animation */
}

.container-items ul li {
  margin-bottom: 10px;
}

/* Button styling */
.btn.ReadMore {
  font-size: 1em;
  padding: 10px 20px;
  border-radius: 5px;
  color: #0098DA;
  border: 1px solid #0098DA;
  background: none;
  transition: background-color 0.3s, color 0.3s;
  animation: fadeInUp 1s ease-in-out; /* Fade-in-up animation */
}

.btn.ReadMore:hover {
  background-color: #0098DA;
  color: white;
}

/* Image container styling */
.container-items-data-img img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  animation: fadeIn 1.2s ease-in-out; /* Fade-in animation */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .container-items {
    flex: 1 1 100%; /* Full width for smaller screens */
    margin: 10px 0;
  }

  .container {
    padding: 10px;
  }
}

/* Keyframes for animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
